<template>
  <div class="enter-security-code">
    <LoadingAllScreen :is-loading.sync="loading" :scroll-lock="false"/>
    <Content>
      <h1 class="h4 text-center mb-4">Enter security code</h1>
      <div class="text text-center mb-6">
        Please check your email for your security code.
          If you could not find the email in the inbox or Spam folder,
           please tap to "Not received code"
      </div>
      <form class="form">
        <BaseInput
          class="form-group input_enter_code"
          :hasError="$v.formData.code.$error"
          placeholder="Enter code"
          v-model="formData.code"
        >
          <template slot="error-message">
            <span v-if="!$v.formData.code.required">Field is required</span>
          </template>
        </BaseInput>
        <div class="resent-code">
          <span class="resent-code-text" @click="redirectToRegistration">
            {{labelResentCode}}
          </span>
        </div>
        <div class="form-group form-group_submit">
          <button
            class="form button button_w-100 button_theme-default button_size-m"
            @click.prevent="submit"
          >
            Continue
          </button>
        </div>
      </form>
      <GeoLocationModal :show-window-modal="showInfoModalAboutGeolocation"></GeoLocationModal>
    </Content>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import GeoLocationModal from '@components/Modals/GeoLocationModal.vue';
import configEnv from '@configEnv';
import differentsServices from '@helpers/differentsServices';

const { required } = require('vuelidate/lib/validators');

export default {
  components: {
    GeoLocationModal,
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      code: {
        required,
      },
    },
    formDataPhone: {
      phone: {
        required,
      },
    },
  },
  data: () => ({
    configEnv,
    formData: {
      code: null,
    },
    formDataPhone: {
      diaCode: '',
      phone: '',
    },
    showInfoModalAboutGeolocation: false,
    countDown: 0,
    firstTime: true,
    resentCode: false,
    labelResentCode: 'Not received code',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  methods: {
    countryChanged(data) {
      this.formDataPhone.diaCode = data.dialCode;
    },
    async submit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$invalid) {
        this.loading = true;
        // eslint-disable-next-line no-underscore-dangle
        try {
          await this.$store.dispatch('auth/setSecurityCode', this.formData);
          const timeModel = setTimeout(() => {
            this.showInfoModalAboutGeolocation = true;
          }, 300);
          const geolocation = await differentsServices.getGeolocation();
          clearTimeout(timeModel);
          const getGeoData = await differentsServices.requestSearchGeoPosition(geolocation);

          if (getGeoData.allowGetGeolocation) {
            await this.$api.auth.updateGeoLocationUsers(getGeoData, true, this.getProfile.id);
          } else if (!getGeoData.allowGetGeolocation) {
            await this.$api.auth.updateGeoLocationUsers(getGeoData, false, this.getProfile.id);
          }
          this.loading = false;
          this.$router.push('create-new-password');
        } catch (err) {
          this.loading = false;
        }

        // this.$api.auth.validateCode(this.formData, this.getProfile.id).then(() => {
        //   this.$router.push('create-new-password');
        // });
      }
    },
    redirectToRegistration() {
      this.$router.push({
        name: 'main',
        params: {
          slide: 3,
        },
      });
    },
  },
};
</script>


<style lang="scss">
  .resent-code {
    float: right;
    margin-bottom: 15px;
  }
  .resent-code-text {
    cursor: pointer;
    color: $lnkColor2;
    font-family: Montserrat,sans-serif;
  }
  .resent-code-text-timer {
    color: $lnkColor2;
    font-family: Montserrat,sans-serif;
  }
  .resent-code-text:active {
    cursor: pointer;
    color: $activeLnkColor2;
  }
  .input_enter_code {
    margin-top: 32px;
  }
  .enter-security-code input {
    background-color: white;
  }
</style>
